import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import { UserAvatarLink_UserFragmentDoc } from '../../../design_system/Link/__generated__/UserAvatarLink.graphql';
export type ViewerNavControlsFragment = { __typename?: 'User', id: string, publicProfile?: { __typename?: 'UserPublicProfile', id: string, firstName: string, lastName: string, photoDownloadUrl?: string | null } | null };

export type UserAvatarButton_UserFragment = { __typename?: 'User', id: string, publicProfile?: { __typename?: 'UserPublicProfile', id: string, firstName: string, lastName: string, photoDownloadUrl?: string | null } | null };

export type ViewerNavControls_FeatureFlagsFragment = { __typename?: 'FeatureFlags', id: string, login: boolean, signup: boolean, mentorshipRequest: boolean };

export const UserAvatarButton_UserFragmentDoc = gql`
    fragment UserAvatarButton_user on User {
  id
  ...UserAvatarLink_user
}
    ${UserAvatarLink_UserFragmentDoc}`;
export const ViewerNavControlsFragmentDoc = gql`
    fragment ViewerNavControls on User {
  id
  ...UserAvatarButton_user
}
    ${UserAvatarButton_UserFragmentDoc}`;
export const ViewerNavControls_FeatureFlagsFragmentDoc = gql`
    fragment ViewerNavControls_featureFlags on FeatureFlags {
  id
  login
  signup
  mentorshipRequest
}
    `;