import isNonEmptyString from '../../../isNonEmptyString';
import {CSSStyles} from '../../../types/CSSStyles';
import Link from '../Link/Link';

import {SerializedStyles, css} from '@emotion/react';
import useMergedRef from '@react-hook/merged-ref';
import React, {forwardRef, useRef} from 'react';
import {AriaButtonOptions, useButton} from 'react-aria';

export type ButtonStyles = Readonly<{
  default: SerializedStyles;
  disabled: SerializedStyles;
  down: SerializedStyles;
  focus: SerializedStyles;
  hover: SerializedStyles;
}>;

export interface Props extends AriaButtonOptions<'button'> {
  readonly label: React.ReactNode;
  readonly children?: React.ReactNode;
  /**
   * @default primary
   */
  readonly labelIsHidden?: boolean;
  readonly sx?: CSSStyles;
  /**
   * @default large
   */
  readonly to?: string;
}

function UnstyledButton(
  props: Props,
  parentRef: React.ForwardedRef<unknown>,
): JSX.Element {
  const {children, label, labelIsHidden, sx, to, ...buttonProps} = props;
  const ref = useRef(null);
  const mergedRef = useMergedRef(parentRef, ref);
  const {buttonProps: buttonRootProps} = useButton(buttonProps, ref);

  if (isNonEmptyString(to)) {
    return (
      <Link
        sx={[css({textDecoration: 'none'}), sx]}
        to={to}
        {...buttonProps}
      >
        {children}
        {!labelIsHidden && <span>{label}</span>}
      </Link>
    );
  }

  return (
    <button
      css={[sx]}
      ref={mergedRef}
      {...buttonRootProps}
    >
      {children}
      <div
        css={[
          labelIsHidden &&
            css({
              display: 'none',
            }),
        ]}
      >
        {label}
      </div>
    </button>
  );
}

export default forwardRef(UnstyledButton);
