import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
export type UserAvatarLink_UserFragment = { __typename?: 'User', id: string, publicProfile?: { __typename?: 'UserPublicProfile', id: string, firstName: string, lastName: string, photoDownloadUrl?: string | null } | null };

export const UserAvatarLink_UserFragmentDoc = gql`
    fragment UserAvatarLink_user on User {
  id
  publicProfile {
    id
    firstName
    lastName
    photoDownloadUrl
  }
}
    `;