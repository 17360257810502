import * as Types from '../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import { ViewerNavControlsFragmentDoc, ViewerNavControls_FeatureFlagsFragmentDoc } from '../Nav/__generated__/ViewerNavControls.graphql';
import { FeedbackButton_FeatureFlagsFragmentDoc } from '../../mentorship/actions/__generated__/FeedbackButton.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalNavigationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GlobalNavigationQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, publicProfile?: { __typename?: 'UserPublicProfile', id: string, firstName: string, lastName: string, photoDownloadUrl?: string | null } | null } | null, featureFlags: { __typename?: 'FeatureFlags', id: string, login: boolean, signup: boolean, mentorshipRequest: boolean, submitFeedback: boolean } };

export type GlobalNavigation_UserFragment = { __typename?: 'User', id: string, publicProfile?: { __typename?: 'UserPublicProfile', id: string, firstName: string, lastName: string, photoDownloadUrl?: string | null } | null };

export type GlobalNavigation_FeatureFlagsFragment = { __typename?: 'FeatureFlags', id: string, login: boolean, signup: boolean, mentorshipRequest: boolean, submitFeedback: boolean };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export const GlobalNavigation_UserFragmentDoc = gql`
    fragment GlobalNavigation_user on User {
  id
  ...ViewerNavControls
}
    ${ViewerNavControlsFragmentDoc}`;
export const GlobalNavigation_FeatureFlagsFragmentDoc = gql`
    fragment GlobalNavigation_featureFlags on FeatureFlags {
  id
  ...ViewerNavControls_featureFlags
  ...FeedbackButton_featureFlags
}
    ${ViewerNavControls_FeatureFlagsFragmentDoc}
${FeedbackButton_FeatureFlagsFragmentDoc}`;
export const GlobalNavigationDocument = gql`
    query GlobalNavigation {
  viewer {
    ...GlobalNavigation_user
  }
  featureFlags {
    ...GlobalNavigation_featureFlags
  }
}
    ${GlobalNavigation_UserFragmentDoc}
${GlobalNavigation_FeatureFlagsFragmentDoc}`;

/**
 * __useGlobalNavigationQuery__
 *
 * To run a query within a React component, call `useGlobalNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalNavigationQuery(baseOptions?: Apollo.QueryHookOptions<GlobalNavigationQuery, GlobalNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalNavigationQuery, GlobalNavigationQueryVariables>(GlobalNavigationDocument, options);
      }
export function useGlobalNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalNavigationQuery, GlobalNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalNavigationQuery, GlobalNavigationQueryVariables>(GlobalNavigationDocument, options);
        }
export function useGlobalNavigationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GlobalNavigationQuery, GlobalNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GlobalNavigationQuery, GlobalNavigationQueryVariables>(GlobalNavigationDocument, options);
        }
export type GlobalNavigationQueryHookResult = ReturnType<typeof useGlobalNavigationQuery>;
export type GlobalNavigationLazyQueryHookResult = ReturnType<typeof useGlobalNavigationLazyQuery>;
export type GlobalNavigationSuspenseQueryHookResult = ReturnType<typeof useGlobalNavigationSuspenseQuery>;
export type GlobalNavigationQueryResult = Apollo.QueryResult<GlobalNavigationQuery, GlobalNavigationQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;