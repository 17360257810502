import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';
import {X} from 'react-feather';

export default function Close(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);

  return <X {...iconProps} />;
}